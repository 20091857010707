<script setup>
import {ref, computed, watch} from "vue";
import TextInput from "@/Components/TextInput.vue";
import {PhPlusCircle, PhMinusCircle, PhCheckCircle} from '@phosphor-icons/vue';
import Tooltip from "@/Components/Tooltip.vue";
import {addToCart} from "@/mixins";

const props = defineProps(['product']);

const input = ref(null);
const inputQuantityStr = ref('' + props.product.cart.quantity);
const inputQuantity = computed(() => {
    let parsed = parseFloat(inputQuantityStr.value);
    if (isNaN(parsed)) {
        return props.product.cart.quantity;
    } else {
        return parsed;
    }
});
const dec = computed(() => props.product.partialQuantities ? 0.25 : 1);
const inc = computed(() => Math.min(props.product.quantityAvailable - props.product.cart.quantity, 1));
const canDecrease = computed(() => inputQuantity.value - dec.value >= 0.5);
const canIncrease = computed(() => inc.value > 0);
const decreaseMessage = computed(() => canDecrease.value ? ("Decrease by " + dec.value) : "Minimum quantity");
const increaseMessage = computed(() => canIncrease.value ? ("Increase by " + inc.value) : "Maximum quantity");

const manualInput = ref(false);
const saving = ref(false);
const unsaved = ref(false);

watch(() => props.product.cart.quantity, (n) => {
    inputQuantityStr.value = '' + n;
    manualInput.value = false;
    unsaved.value = false;
});

function increase() {
    if (canIncrease.value) {
        let v = inputQuantity.value + inc.value;
        if (inc.value >= 1) {
            v = Math.floor(v);
        }
        inputQuantityStr.value = '' + v;
        submitValue();
    }
}

function decrease() {
    if (canDecrease.value) {
        inputQuantityStr.value = '' + (inputQuantity.value - dec.value);
        submitValue();
    }
}

function submitValue(blur) {
    let v;
    if (props.product.partialQuantities) {
        v = Math.round(inputQuantity.value * 4) / 4;
        if (v > 0) {
            v = Math.max(0.5, v);
        }
    } else {
        v = Math.round(inputQuantity.value);
    }
    v = Math.min(v, props.product.quantityAvailable);
    inputQuantityStr.value = '' + v;
    if (v === props.product.cart.quantity) {
        manualInput.value = false;
        unsaved.value = false;
    } else {
        if (blur) {
            unsaved.value = true;
        } else {
            saving.value = true;
            addToCart(props.product.productId, v, () => {
                saving.value = false;
            });
            input.value.blur();
        }
    }
}

function onFocus() {
    manualInput.value = true;
    input.value.select();
}

const buttonTextClass = 'text-gray-600 hover:text-indigo-500';

defineExpose({
    focus: () => input.value.focus(),
    select: () => input.value.select(),
});

</script>

<template>
    <div class="text-center">
        <label class="sr-only">Quantity, {{ product.id }}</label>
        <form @submit.prevent="submitValue(false)" v-if="product.inStock">
            <div class="flex-row flex items-center justify-center">
                <Tooltip :message="decreaseMessage" v-if="!manualInput">
                    <button type="button"
                            @click="decrease"
                            :disabled="!canDecrease"
                            class="mr-1 inline-flex h-10 w-10 flex-shrink-0 rounded-full p-1 disabled:opacity-25 hover:bg-gray-200"
                            :class="buttonTextClass">
                        <PhMinusCircle weight="fill" class="w-full h-full"/>
                    </button>
                </Tooltip>
                <template v-else>
                    <div class="mr-1 inline-flex h-10 w-10 flex-shrink-0"></div>
                </template>
                <TextInput
                    ref="input"
                    type="text"
                    inputmode="decimal"
                    class="text-center block w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    v-model="inputQuantityStr"
                    @focus="onFocus"
                    @change="submitValue(true)"
                    @blur="submitValue(true)"
                    :disabled="saving"
                />
                <Tooltip :message="increaseMessage" v-if="!manualInput">
                    <button type="button"
                            @click="increase"
                            :disabled="!canIncrease"
                            class="ml-1 inline-flex h-10 w-10 flex-shrink-0 rounded-full p-1 disabled:opacity-25 hover:bg-gray-200"
                            :class="buttonTextClass">
                        <PhPlusCircle weight="fill" class="w-full h-full"/>
                    </button>
                </Tooltip>
                <Tooltip message="Save changes" v-if="manualInput">
                    <button type="submit"
                            class="ml-1 inline-flex h-10 w-10 flex-shrink-0 rounded-full p-1 disabled:opacity-25 hover:bg-gray-200"
                            :class="buttonTextClass"
                            :disabled="saving">
                        <PhCheckCircle weight="fill" class="w-full h-full"/>
                    </button>
                </Tooltip>
            </div>
        </form>
        <span v-else class="block text-sm text-gray-400">
            No Longer Available
        </span>
        <button class="text-sm" v-if="saving">Saving...</button>
        <button class="text-sm" v-else-if="unsaved" @click="submitValue(false)">Unsaved changes!</button>
        <span class="text-sm" v-else-if="manualInput">Enter <template
            v-if="props.product.cart.quantity !== 1">new</template> quantity</span>
        <slot v-else></slot>
    </div>
</template>
