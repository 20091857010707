<template>
    <div v-if="labels.length > 0" class="absolute top-0 left-0 flex flex-col items-start">
        <span v-for="label in labels"
              :class="labelClasses(label)"
              class="flex items-center h-7 text-xs font-bold mt-1 mb-0.5 py-1 px-2 uppercase rounded-r sm:rounded uppercase drop-shadow-md sm:-ml-2">
            <template v-if="label === 'Sale' && product.discount">
                Save
                <template v-if="product.discount.percentage">
                    {{ product.discount.amount }}%
                </template>
                <template v-else>
                    ${{ product.discount.amount }}
                </template>
            </template>
            <template v-else>
                <template v-if="label === 'Trending'">
                    <PhFire weight="fill" class="inline-block h-4 w-4 mr-1"/>
                </template>
                <template v-else-if="label === 'Favorite'">
                    <PhHeart weight="fill" class="inline-block h-4 w-4 text-red-400 mr-1"/>
                </template>
                {{ label }}
            </template>
        </span>
    </div>
</template>

<script setup>
import {PhFire, PhHeart} from '@phosphor-icons/vue';
import {computed} from "vue";
import {usePage} from "@inertiajs/vue3";

const props = defineProps({
    product: Object,
});

const favorite = computed(() => props.product.productId in usePage().props.favorites.products);
const labels = computed(() => {
    let allLabels = [...(favorite.value ? ["Favorite"] : []), ...props.product.labels];
    if (allLabels.includes('Trending') && allLabels.includes('Just Arrived')) {
        return allLabels.filter((element) => element !== 'Just Arrived');
    } else {
        return allLabels;
    }
});

function labelClasses(label) {
    if (label === 'Sale') {
        return 'text-white bg-green-500';
    } else if (label === 'Trending') {
        return 'text-gray-700 bg-white';
    } else {
        return 'text-gray-600 bg-gray-200';
    }
}

</script>
