<script setup>
import {computed, ref} from 'vue';
import {Link, usePage} from '@inertiajs/vue3';
import InputQuantity from "@/Components/InputQuantity.vue";
import {addToCart} from "@/mixins";
import PrimaryButton from "@/Components/PrimaryButton.vue";

const props = defineProps({
    productId: Number,
    textClass: {
        type: String,
        default: 'text-base',
    }
});
const cart = computed(() => usePage().props.cart.products);
const cartProduct = computed(() => Object.values(cart.value).find((el) => el.productId === props.productId));

const input = ref(null);

function focusOnQuantity() {
    if (input.value) {
        input.value.focus();
        input.value.select();
    }
}
</script>
<template>
    <div class="flex flex-row items-start justify-center">
        <template v-if="cartProduct">
            <div class="w-full relative">
                <InputQuantity ref="input" :product="cartProduct">
                    <Link :href="route('cart.show')"
                          class="text-sm"
                    >{{ cartProduct.cart.uom }} in cart
                    </Link>
                </InputQuantity>
            </div>
        </template>
        <PrimaryButton v-else @click="addToCart(productId, 1, focusOnQuantity)"
                       class="w-full h-full" :class="textClass">
            Add to Cart
        </PrimaryButton>
    </div>
</template>
